import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import { COOKIE } from "constants/cookie";
import { getLocaleLanguage } from "utils/locale";
import { LocaleKey } from "constants/locale.constant";

const resources = {
  "zh-TW": { translation: getLocaleLanguage(LocaleKey['zh-TW']) },
  "zh-CN": { translation: getLocaleLanguage(LocaleKey['zh-CN']) },
  "en-US": { translation: getLocaleLanguage(LocaleKey['en-US']) },
  "vi-VN": { translation: getLocaleLanguage(LocaleKey['vi-VN']) },
  "th-TH": { translation: getLocaleLanguage(LocaleKey['th-TH']) }
};

i18n.use(initReactI18next).init({
  resources,
  lng: Cookies.get(COOKIE.LANG) || "en-US" || document.documentElement.lang,
  interpolation: {
    escapeValue: false,
  },
  debug: false,
});

export default i18n;
